<template>
    <div>
  <addField
    :path="currentTable.tableUrl"
    :additionalAttributes="{ function: 'downloadImageRequest', requestType: 'ajax' }"
    :dialogAttributes="{}"
    :showDialog="openDialogFlag"
    @dialog-closed="openDialogFlag == false"
  >
    <div class="ml-2">
      <primaryButton
        type="button"
        label="Get Explorer"
        icon="mdi-cloud-download-outline"
        color="primary"
        id="downloadExplorer"
      ></primaryButton>
    </div>
  </addField></div>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton, addField },
  props: ["currentTable"],
  data() {
    return { openDialogFlag: false };
  },
};
</script>